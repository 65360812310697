import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { DefaultService, UserJson } from '@cportal/api';
import { inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { delay, pipe, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';
import { KeycloakService } from 'keycloak-angular';

interface BooksState {
  user: UserJson;
  isLoading: boolean;
  error?: unknown;
}

const initialState: BooksState = {
  isLoading: false,
  user: {},
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(
    (
      store,
      defaultService = inject(DefaultService),
      keycloakService = inject(KeycloakService),
    ) => ({
      getUser: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { isLoading: true })),
          switchMap(() =>
            defaultService.userGet().pipe(
              tapResponse({
                next: response => {
                  patchState(store, { user: response, isLoading: false });
                },
                error: error => {
                  patchState(store, { error, isLoading: false });
                },
              }),
            ),
          ),
        ),
      ),
      async logout(): Promise<void> {
        await keycloakService.logout();
      },
      login(): void {
        keycloakService.login();
      },
    }),
  ),
);
